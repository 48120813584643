<template>
  <tr>
    <td :colspan="colspan" style="padding:10px;">
      <center>
        {{ $t('common.messages.noData', locale) }} 
        <v-btn
          v-if="onAdd"
          @click="onAdd"
          style="margin-left: 20px;"
          color="primary"
        >
          {{ $t('common.newItem', locale) }}
        </v-btn>
      </center>
    </td>
  </tr>
</template>
<script>
  export default {
    props: {
      locale: {
        type: String,
        required: true,
      },
      colspan: {
        type: Number,
        default: 0
      },
      onAdd: {
        type: Function,
        default: null,
      }
    }
  }
</script>

