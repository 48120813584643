<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-icon 
        v-bind="attrs"
        v-on="on"
        :color="prepareColor(item)"
      >
        mdi-calendar-month-outline
      </v-icon>
    </template>
    <span>{{prepareInfo(item)}}</span>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    prepareColor (v) {
      if (v.closed) return 'red'
      if (v.date && v.time) return 'green'//''#27ff00'
      if (!v.finished) return '#cdf9c5'
      return 'gray'
    },
    prepareInfo (v) {
      if (v.closed) return 'Vencido'
      if (v.date && v.time) return 'Activo'//''#27ff00'
      if (!v.finished) return 'Pendiente de activarse'
      return 'Finalizado'
    },
  },
}
</script>

