<template>
  <draggable
    :list="items"
    tag="tbody"
    :draggable="'.itemDraggable'"
    @end="onSort(items)"
  >

    <tr
      v-for="(item, index) in items"
      :key="index"
      class="itemDraggable"
    >
      <td width="30px">
        <v-icon
          small
          class="page__grab-icon"
        >
          mdi-cursor-move
        </v-icon>
      </td>
      <td width="45px" style="padding:0 0 0 10px;text-align:center;">
        <center>
          <v-checkbox
            v-model="multiSelected"
            :key="item.ID"
            :value="item.ID"
            @change="handleChangeMultiSelect(multiSelected)"
            hide-details
            style="margin:0;"
          />
        </center>
      </td>
      <td>
        <v-icon
          v-if="item.Hide"
          :color="item.Hide ? '#CCCCCC' : 'green'"
          small 
          style="margin-right: 10px"
        >
          {{ item.Hide ? 'mdi-eye-off' : 'mdi-eye' }}
        </v-icon>
        <a @click="onEditItem(item.ID)" style="cursor: pointer;" color="primary"><b>{{ item.Name && item.Name.length > 0 ? item.Name : '...' }}</b></a>
      </td>
      <td>
        {{ item.Message }}
      </td>
      <td style="text-align:center;width:30px;">
        <v-tooltip bottom v-if="onCloneItem">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              style="cursor: pointer;margin-left: 0px;"
              @click="onCloneItem(item.ID)"
            >
              mdi-content-copy
            </v-icon>
          </template>
          <span>clonar</span>
        </v-tooltip>
      </td>
      <td style="text-align:left;">
        <availability-info :item="item" :locale="locale" />
      </td>
      <td style="text-align:left;width:30px;">
        <availability-icon :item="item.Status" />
      </td>
      <!--td style="text-align:right;">
        <v-icon
          v-if="onEditItem"
          small
          class="mr-2"
          @click="onEditItem(item.ID)"
        >
          mdi-pencil
        </v-icon>
        <delete-item
          v-if="onDeleteItem"
          :id="item.ID"
          :onDeleteItem="onDeleteItem"
          icon="mdi-delete"
          :locale="locale"
        />
      </td-->
    </tr>
  </draggable>
</template>
<script>
  import draggable from 'vuedraggable'
  import AvailabilityInfo from './AvailabilityInfo'
  import AvailabilityIcon from './AvailabilityIcon'
  export default{
    components: {
      draggable,
      AvailabilityInfo,
      AvailabilityIcon,
    },
    props: {
      contentID:{
        type: String,
        default: null,
      },
      items: {
        type: Array,
        required: true,
      },
      onDeleteItem: {
        type: Function,
        default: null,
      },
      onEditItem: {
        type: Function,
        default: null,
      },
      onCloneItem: {
        type: Function,
        deafult: null,
      },
      onSort: {
        type: Function,
        default: null,
      },
      locale: {
        type: String,
        required: true,
      },
      onChangeMultiSelect: {
        type: Function,
        default: null,
      },
      clearMultiSelect: {
        type: String,
        default: null,
      },
    },
    data: () => ({
      multiSelected: [],
    }),
    watch: {
      clearMultiSelect () {
        this.multiSelected = []
        this.onChangeMultiSelect([])
      },
      contentID () {
        this.multiSelected = []
        this.onChangeMultiSelect([])
      },
    },
    mounted () {
      this.multiSelected = []
      this.onChangeMultiSelect([])
    },
    methods: {
      handleChangeMultiSelect (v) {
        this.onChangeMultiSelect(v)
      }
    },
  }
</script>
<style scoped>
.itemDraggable {
  width: 100%;
}
.itemDraggable td{
  border: 0.5px solid white !important;
  /*border-bottom: 1px solid #fcf9f9 !important;*/
}
.itemDraggable:nth-child(odd){
  background: #f6f5f2;
  background_: #fcf9f9;
}
.itemDraggable:nth-child(even){
  background: #f5f0e7;
  background_: white;
}
</style>
