<template>
  <p style="margin:0;padding:0;">
    <small>
      {{ prepareInfo(item, locale) }}
    </small>
  </p>
</template>
<script>
import utils from '@/services/utils'
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  methods: {
    prepareInfo (v, locale) {
      const {
        ContentID,
        DateStart,
        DateEnd,
        TimeStart,
        TimeEnd,
        Per,
        Rec,
        IsAllDays,
        IsAllTimes,
        DayOfWeek,
        DayOfMonth,
        Alt
      } = v

      if (!ContentID) return this.$t('common.availability.info.noTemp', locale).concat('.')

      let result = ''
      if (Per === 1) 
        result = this.$t('common.availability.info.activeDay', locale).replace('?', DateStart.split('T').shift())
      else {
        result = this.$t('common.availability.info.activeFrom', locale).replace('?', DateStart.split('T').shift())
        if (IsAllDays) 
          result += this.$t('common.availability.info.noEndDate', locale)
        else
          result += this.$t('common.availability.info.untilThe', locale).replace('?', DateEnd.split('T').shift())
      }
      if (IsAllTimes) {
        result += this.$t('common.availability.info.atAllHours', locale)
      } else {
        result += this.$t('common.availability.info.fromTo', locale).replace('?', utils.convertTime(TimeStart).split(' ').join('')).replace('?', utils.convertTime(TimeEnd).split(' ').join(''))
      }

      if (Per === 2) {
        if (Rec === 0) {
          const aux = DayOfMonth ? JSON.parse(DayOfMonth) : []
          result += this.$t('common.availability.info.theDays', locale).replace('?', aux.join(','))
        } else if (Rec === 1) {
          const aux = DayOfWeek ? JSON.parse(DayOfWeek) : []
          const auxWeek = []
          for (const d of aux) {
            auxWeek.push(this.$t(`common.s${d === 0 ? 6 : d - 1}`, locale).toLowerCase())
          }
          result += this.$t('common.availability.info.the', locale).replace('?', auxWeek.join(', '))
        }
      }
      
      if (Alt > 1) result += `. [${this.$t('common.availability.info.alt', locale).replace('?', Alt)}]`

      return result.concat('.')
    },

  },
}
</script>

